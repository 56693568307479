import {MutableRefObject} from 'react';
import styled from 'styled-components';
import {captureScreenshot} from '../helpers/screenshot';
import {ProgressBar} from './progressBar';

/*
 * Styles.
 */

const RoastResultContainer = styled.div`
  margin: 13px;
  color: #fff;
  max-width: 400px;
`;

const RoastHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: space-evenly;
  align-items: center;
`;

const FollowCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
`;

const FollowCountSpan = styled.span<{$bold?: boolean}>`
  font-size: 14px;
  color: #fff;
  ${props => props.$bold && "font-weight: bold;"}
`;

const ProfilePic = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #fff;
`;

const RoastStats = styled.div`
  font-family: "Fredoka", sans-serif;
  margin-bottom: 10px;
`;

const StatRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f1f1f1;
  padding: 18px;
  border-radius: 18px;
  margin: 8px;
  gap: 6px;
`;

const StatLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #353535;
  width: 100%;
  text-align: left;
`;

const RoastText = styled.div`
  font-size: 12px;
  color: #353535;
  padding: 5px;
  border-radius: 5px;
  text-align: left;
`;

const StyledButton = styled.button<{$bgColor: string}>`
  margin-top: 10px;
  background-color: ${props => props.$bgColor};
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 8px 18px;
  font-family: "Fredoka", sans-serif;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  font-weight: bold;
`;

/*
 * Types./
 */

interface RoastMyIgResultProps {
  data: any;
  inputRef: MutableRefObject<HTMLDivElement | null>;
}

function calculateRoast(roastPoints?: number) {
  return 100 - (roastPoints ?? 0) * 10
}

export const RoastMyIgResult = (props: RoastMyIgResultProps) => {
  const {
    data,
    inputRef,
  } = props;

  const {
    profile_pic,
    feed_roast,
    feed_roast_score,
    bio_roast,
    bio_roast_score,
    follower_ratio_roast,
    follower_ratio_roast_score,
    follower_num,
    followee_num,
  } = data;


  const profileImageObjectURL = `data:image/png;base64,${profile_pic}`;

  const feedRoastScoreNormalized = calculateRoast(feed_roast_score);
  const bioRoastScoreNormalized = calculateRoast(bio_roast_score);
  const followerRatioRoastScoreNormalized = calculateRoast(follower_ratio_roast_score);

  return (
    <RoastResultContainer>
      <RoastHeader>
        <ProfilePic src={profileImageObjectURL} alt="Profile" />
        <FollowCountContainer>
          <FollowCountSpan $bold>{follower_num}</FollowCountSpan>
          <FollowCountSpan>Followers</FollowCountSpan>
        </FollowCountContainer>
        <FollowCountContainer>
          <FollowCountSpan $bold>{followee_num}</FollowCountSpan>
          <FollowCountSpan>Following</FollowCountSpan>
        </FollowCountContainer>
      </RoastHeader>

      <RoastStats>
        <StatRowContainer>
          <StatLabel>Feed</StatLabel>
          <ProgressBar progress={feedRoastScoreNormalized} background="#C43BFF" />
          <RoastText>{feed_roast}</RoastText>
        </StatRowContainer>
        <StatRowContainer>
          <StatLabel>Bio</StatLabel>
          <ProgressBar progress={bioRoastScoreNormalized} background="#8C74FF" />
          <RoastText>{bio_roast}</RoastText>
        </StatRowContainer>
        <StatRowContainer>
          <StatLabel>Follow Ratio</StatLabel>
          <ProgressBar progress={followerRatioRoastScoreNormalized} background="#48B8FF" />
          <RoastText>{follower_ratio_roast}</RoastText>
        </StatRowContainer>
      </RoastStats >
      <StyledButton
        id="shareButton"
        $bgColor="#F0F"
        onClick={async () => await captureScreenshot(inputRef)}>🤣 Share</StyledButton>
    </RoastResultContainer >
  );
};
