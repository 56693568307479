import React, {FC} from 'react';
import styled, {keyframes} from 'styled-components';

/*
 * Props.
 */

interface SkeletonProps {
  className?: string;
  /* Width of the skeleton */
  width?: string;
  /* Height of the skeleton */
  height?: string;
  /* Border Radius of the skeleton */
  borderRadius?: string;
  /* Duration of the animation */
  duration?: number;
  /* Content which width will be used to determine the width of the skeleton */
  hollowContent?: React.ReactNode;
}

/*
 * Styles.
 */

const skeletonKeyFrames = keyframes`
  from {
    transform: translateX(calc(-200px));
  }
  to {
    transform: translateX(calc(200px + 100%))
  }
`;

interface SkeletonStyleProps {
  $duration?: number;
}

const StyledSkeletonSpan = styled.div<SkeletonStyleProps>`
  position: relative;
  display: inline-block;
  line-height: 1;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-size: 200px 100%;
    background-repeat: no-repeat;
    animation: ${skeletonKeyFrames} ${(p) => p.$duration || 1.2}s ease-in-out infinite;
  }

  background-color: #e8eaed;

  &::before {
    background-image: linear-gradient(90deg, #e8eaed, #d4d6d9, #e8eaed);
  }
`;

const StyledTransparentSpan = styled.span`
  width: fit-content;
  opacity: 0;
`;

/*
 * Component.
 */

export const Skeleton: FC<SkeletonProps> = ({
  width = '100%',
  height = '100%',
  borderRadius = '4px',
  ...props
}) => (
  <StyledSkeletonSpan
    className={props.className}
    style={{
      width,
      height,
      borderRadius,
    }}
    $duration={props.duration}
  >
    {props.hollowContent ? <StyledTransparentSpan>{props.hollowContent}</StyledTransparentSpan> : '\u200c'}
  </StyledSkeletonSpan>
);

