import {useRef, useState} from 'react';
import {useQuery} from 'react-query';
import styled from 'styled-components';
import {postAsync} from '../http/http';
import titleLogo from '../roastmyig.png';
import {RoastMyIgResult} from './roastResult';
import {Skeleton} from './skeleton';

/*
 * Styles.
 */

const RoastContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;

  border: 2px solid #ff00ff;
  border-radius: 20px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.8);
  font-family: "Fredoka", sans-serif;
  height: 40px;
  width: 80%
`;

const InputField = styled.input`
  padding: 8px;
  font-size: 16px;
  font-family: "Fredoka", sans-serif;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%
`;

const RoastButton = styled.button`
  background-color: #ff00ff;
  margin: 5px;
  color: #ffff00;
  border: none;
  border-radius: 20px;
  padding: 5px 12px;
  font-family: "Fredoka", sans-serif;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;

  &:disabled,
  &[disabled]{
    background-color: #cccccc;
    color: #666666;
  }

`;

const StyledA = styled.a<{$shouldRenderResults: boolean}>`
  margin-top: -15px;
  margin-bottom: 5px;

  ${(p) => p.$shouldRenderResults ? 'margin: 10px;' : ''}

  img {
    ${(p) => p.$shouldRenderResults ? 'width: 100px;' : 'width: 300px;'}
  }
`;

const HeaderContainer = styled.div<{$shouldRenderResults: boolean}>`
  display: flex;
  ${(p) => p.$shouldRenderResults ? 'flex-direction: row;' : 'flex-direction: column;'}
  align-items: center;
`;

/*
 * Interface.
 */
interface RoastComponentProps {
}

/*
 * Component.
 */

export const RoastComponent = (props: RoastComponentProps) => {
  const [igHandle, setIgHandle] = useState('');
  const [isRoastClicked, setIsRoastClicked] = useState(false);
  const inputRef = useRef<HTMLImageElement>(null);

  const {isLoading, isError, data, error} = useQuery({
    queryKey: [igHandle],
    queryFn: async () => postAsync('/api/roast_ig', {username: igHandle}),
    enabled: Boolean(igHandle) && isRoastClicked,
    onSettled() {
      setIsRoastClicked(false);
    },
    retry: false
  })

  const handleRoast = () => {
    if (igHandle) {
      setIsRoastClicked(true);
    }
  };

  const shouldRenderResults = data && !isError && !isLoading && !isRoastClicked;

  return (
    <RoastContainer id="roastContainer">
      <HeaderContainer $shouldRenderResults={shouldRenderResults}>
        <StyledA
          href={process.env.REACT_APP_URL}
          $shouldRenderResults={shouldRenderResults}
        >
          <img className="logo-image" src={titleLogo} alt="Roast my IG" />
        </StyledA>
        <InputContainer ref={inputRef}>
          <InputField
            type="text"
            placeholder="Enter your Instagram handle"
            value={igHandle}
            onChange={(e) => setIgHandle(e.target.value)}
          />
          {igHandle && (
            <RoastButton onClick={handleRoast} disabled={isRoastClicked} >
              {isLoading ? 'Roasting...' : 'Roast'}
            </RoastButton>
          )}
          {isError && renderError(error)}
        </InputContainer>

        {!isError && isRoastClicked && (
          <Skeleton width="70%" height="30px" borderRadius="5px" />
        )}
      </HeaderContainer>

      {shouldRenderResults && (
        <RoastMyIgResult
          data={data}
          inputRef={inputRef}
        />
      )}
    </RoastContainer>
  );
};

function renderError(error: any) {
  if (error.response.status === 400) {
    return (
      <p>Profile is private</p>
    );
  }

  if (error.response.status === 404) {
    return (
      <p>Profile not found</p>
    );
  }

  return (
    <p>Something went wrong</p>
  );
}

