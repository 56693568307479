import {forwardRef} from 'react';
import styled from 'styled-components';

const StickyContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  z-index: 1000;
`;

const Button = styled.a`
  text-decoration: none;
  color: #FFFFFF;
  background-color: #FFCB3D;
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #FF5F5F;
  }
`;

const Image = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 3px;
`;

const Text = styled.span`
  color: #000
`

export const Coffee = forwardRef<HTMLDivElement, any>((props) => {
  return (
    <StickyContainer id={props.id}>
      <Button href="https://www.buymeacoffee.com/robechun" target="_blank">
        <Text>robechun</Text>
        <Image src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" />
      </Button>
    </StickyContainer>
  );
});
