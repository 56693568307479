import styled from 'styled-components';

/*
 * Interface
 */

interface ProgressBarProps {
  progress: number;
  background: string;
}

/*
 * Styles.
 */

const Container = styled.div`
  background: #ffffff;
  border-radius: 24px;
  height: 18px;
  transition: width 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Neon glow */
  width: 100%;
`;

const Filler = styled.div<{$progress: number; $background: string}>`
  height: 100%;
  width: ${props => props.$progress}%;
  text-align: right;
  background: ${props => props.$background};
  border-radius: 24px;
  height: 18px;
`;

export const ProgressBar = (props: ProgressBarProps) => {
  const {progress, background} = props;

  return (
    <Container>
      <Filler $progress={progress} $background={background}>
      </Filler>
    </Container>
  );
};
