import {MutableRefObject} from 'react';
import domtoimage from 'dom-to-image-more';

const adjustClone = (_: Node, clone: Node, after: boolean) => {
  if (!after && clone instanceof HTMLElement) {
    clone.style.fontFamily = 'Fredoka, sans-serif !important';
  }

  return clone;
}

export async function captureScreenshot(
  inputRef: MutableRefObject<HTMLDivElement | null>,
) {
  if (!inputRef) {
    return;
  }

  const filter = (node: Node) => {
    if (node instanceof HTMLElement) {
      if (node.id === "buymeacoffee" ||
        node.id === "shareButton") {
        return false;
      }
    }

    return true
  }



  const el = document.getElementById('roastContainer') as HTMLElement

  const marginScale = 1.1;

  // throwaway for first time due to bug with dom2image
  await domtoimage.toJpeg(el, {
    filter: filter,
    adjustClonedNode: adjustClone,
    height: el.clientHeight,
    width: el.clientWidth,
    background: 'linear-gradient(to bottom right, #ff00ff, #00ffff)',
    scale: 3,
    style: {
      background: 'linear-gradient(to bottom right, #ff00ff, #00ffff)',
    }
  })
  const imageJpg = await domtoimage.toJpeg(el, {
    filter: filter,
    adjustClonedNode: adjustClone,
    height: el.clientHeight * marginScale,
    width: el.clientWidth * marginScale,
    background: 'linear-gradient(to bottom right, #ff00ff, #00ffff)',
    scale: 3,
    style: {
      background: 'linear-gradient(to bottom right, #ff00ff, #00ffff)',
    }
  })


  const imageFile = dataUrlToFile(imageJpg, 'RoastIG.jpg');
  if (!imageFile) {
    return;
  }

  const data = {
    files: [imageFile],
  }

  if (navigator.canShare && navigator.canShare(data)) {
    try {
      await navigator.share(data);
    } catch (e) {
      console.error(e);
    }
  } else {
    const link = document.createElement('a');
    link.href = imageJpg;
    link.download = 'screenshot.png';
    link.click();
  }
};

function dataUrlToFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',');
  if (arr.length < 2) {
    return undefined;
  }

  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return undefined;
  }

  const mime = mimeArr[1];
  const buff = Uint8Array.from(atob(arr[1]), (m) => m.codePointAt(0)!)
  return new File([buff], filename, {type: mime});
  }
