import {useEffect} from 'react';
import {RoastComponent} from './components/roastMain';
import styled from 'styled-components';
import {Coffee} from './components/buyMeACoffee';
import ReactGA from 'react-ga4';

/*
 * Styles.
 */

const Container = styled.div`
  height: 100%;
  text-align: center;
`;

export function App() {
  useEffect(() => {
    ReactGA.initialize("G-P8MWT6FN24");
    // Send pageview with a custom path
    ReactGA.send({hitType: "pageview", page: "/", title: "Landing Page"});
  }, [])

  return (
    <Container id="blah">
      <RoastComponent />
      <Coffee id="buymeacoffee" />
    </Container>
  )
}
