import CryptoJS from 'crypto-js';
import axios, {AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders} from 'axios';
import {HttpFullSendOptions, RequestMethodsEnum} from './httpTypes';

const requester = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // TODO later: csrf
});

// Function to generate HMAC
function generateHMAC(message: string) {
  if (!process.env.REACT_APP_HMAC_SIGNATURE) {
    throw new Error('REACT_APP_HMAC_SIGNATURE is not set');
  }

  return CryptoJS.HmacSHA256(message, process.env.REACT_APP_HMAC_SIGNATURE).toString(CryptoJS.enc.Hex);
}

export async function sendAsync(options: HttpFullSendOptions): Promise<AxiosResponse> {
  // Build the options for our request.
  const {method, path, body, timeout} = options;
  const headers: RawAxiosRequestHeaders = {
    'X-HMAC-SIGNATURE': generateHMAC(path)
  }

  const timeoutDelay = timeout || 30000;

  const axiosOptions: AxiosRequestConfig = {
    url: path,
    method,
    headers,
    // responseType: options.responseType,
    timeout: timeoutDelay,
  };

  // If a body was provided, add it.
  if (body) {
    axiosOptions.data = body;
  }

  Object.assign(headers, options.headers);

  try {
    // Perform the request.
    const response = await requester.request(axiosOptions);

    return response;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }

    throw error;
  } finally {
  }
}

/*
 * GET.
 */

/** GET the resource at the specified path. */
export async function fetchAsync(path: string, options?: HttpFullSendOptions): Promise<any> {
  try {
    const response = await sendAsync({
      method: RequestMethodsEnum.GET,
      path,
      ...options,
    });

    // If the response is empty, throw an error
    throwIfEmpty(RequestMethodsEnum.GET, path, response);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/*
 * POST.
 */

/** POST the provided body at the specified path. */
export async function postAsync(path: string, body?: any, options?: HttpFullSendOptions): Promise<any> {
  try {
    const response = await sendAsync({
      method: RequestMethodsEnum.POST,
      path,
      body,
      ...options,
    });

    // If the response is empty, throw an error
    throwIfEmpty(RequestMethodsEnum.POST, path, response);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


function throwIfEmpty(method: RequestMethodsEnum, path: string, response: AxiosResponse) {
  if (response.data || response.status === 204) {
    return;
  }

  throw new Error(`Empty response for ${method} ${path}`);
}

