export enum RequestMethodsEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface HttpHeaders {
  [T: string]: string;
}

export interface HttpSendOptions {
  headers?: HttpHeaders;
  timeout?: number;
  skipBaseUrl?: boolean;
  responseType?: ResponseType;
}

export interface HttpFullSendOptions extends HttpSendOptions {
  method: RequestMethodsEnum;
  path: string;
  body?: unknown;
  responseType?: ResponseType;
}

